import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/SEO";

function KomIFormPaaEnCrosstrainer() {
  return (
    <>
      <SEO
        title="Kom i form på en crosstrainer derhjemme."
        description="Læs mere om hvilken crosstrainer vi anbefaler til dit hjem og hvilke fordele der er ved at træne på en crosstrainer."
      />
      <article className="flex flex-col mx-auto max-w-4xl my-12">
        <h1 className="font-bold text-4xl mb-4">
          Kom i form på en crosstrainer derhjemme
        </h1>
        <p className="mb-4 text-2xl leading-10">
          Crosstrainers er populære fitnessmaskiner, der giver en alsidig og
          lavimpact træning til hele kroppen. Disse maskiner er et fremragende
          alternativ til andre kardiovaskulære maskiner, såsom løbebånd og
          motionscykler, fordi de giver en mere helkrops træning og er skånsomme
          mod leddene. En af de mest populære crosstrainers på markedet er <Link className="font-bold border-b border-black" to="/andet/odin-c200-crosstrainer">Odin
          C200 Crosstrainer</Link>. Her er nogle af fordelene ved at bruge denne
          træningsmaskine og hvorfor en crosstrainer generelt kan være god for
          kroppen.
        </p>
        <h2 className="font-bold text-3xl my-4">
          Forbedrer kardiovaskulær sundhed
        </h2>
        <p className="mb-4 text-2xl leading-10">
          Crosstrainers er en god måde at forbedre kardiovaskulær sundhed på.
          Denne maskine giver en træning, der får hjertet til at pumpe hurtigere
          og forbedrer blodcirkulationen i hele kroppen. Dette kan bidrage til
          at reducere risikoen for hjertesygdomme, slagtilfælde og andre
          sundhedsproblemer. Forskning har vist, at regelmæssig brug af en
          crosstrainer kan hjælpe med at forbedre både kardiovaskulær sundhed og
          kondition.
        </p>
        <h2 className="font-bold text-3xl my-4">Forbrænder kalorier og fedt</h2>
        <p className="mb-4 text-2xl leading-10">
          Crosstrainers er også et fremragende redskab til at forbrænde kalorier
          og fedt. Ved at bruge hele kroppen i en lav-impact træning, kan du
          forbrænde en masse kalorier på kort tid. Odin C200 Crosstrainer har en
          række forskellige modstandsniveauer, som gør det muligt at justere
          træningsintensiteten og dermed øge forbrændingen af kalorier og fedt.
          Dette er især nyttigt for personer, der ønsker at tabe sig eller
          vedligeholde en sund vægt.
        </p>
        <h2 className="font-bold text-3xl my-4">Styrker musklerne</h2>
        <p className="mb-4 text-2xl leading-10">
          Crosstrainers er også et godt redskab til at styrke musklerne. Under
          træningen arbejder musklerne i hele kroppen sammen for at skubbe og
          trække på maskinen. Dette kan bidrage til at opbygge styrke i ben,
          arme, ryg og kerne. Odin C200 Crosstrainer har også håndtag, som gør
          det muligt at træne armene, mens benene arbejder på pedalerne. Dette
          betyder, at du kan træne hele kroppen samtidig og opbygge muskelstyrke
          på en mere effektiv måde.
        </p>
        <h2 className="font-bold text-3xl my-4">Skånsom træning</h2>
        <p className="mb-4 text-2xl leading-10">
          En af de største fordele ved at bruge en crosstrainer er, at det er en
          lav-impact træning, som er skånsom mod leddene. Dette er især vigtigt
          for personer, der har skader eller ledproblemer. Crosstrainers giver
          en jævn bevægelse, der ikke belaster knæ, hofter eller ankler på samme
          måde som løb eller højintensiv træning kan. Odin C200 Crosstrainer er
          også designet med en blød og støttende polstring på pedalerne, som
          giver ekstra komfort under træningen.
        </p>
      </article>
    </>
  );
}

export default KomIFormPaaEnCrosstrainer;
